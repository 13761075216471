var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('NavComponent'),_c('b-container',[_c('p',[_vm._v("this is call content")])]),_c('b-container',[_c('div',[_c('div',{style:({
          marginTop: '10px',
          marginBottom: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '36%',
        })},[_c('div',{style:({
            display: 'flex',
            alignItems: 'center',
          })},[_c('span',{style:({
              backgroundColor: 'lightblue',
              padding: '3px',
            })},[_vm._v(_vm._s(_vm.report.userId))]),_c('div',{style:({
              marginRight: '5px',
            })},[_vm._v(" : 신고 한 유저 ")])]),_c('div',{style:({
            display: 'flex',
            alignItems: 'center',
          })},[_c('span',{style:({ backgroundColor: 'lightcoral', padding: '3px' })},[_vm._v(_vm._s(_vm.report.targetUserId))]),_c('div',{style:({
              marginRight: '5px',
            })},[_vm._v(" : 신고 당한 유저 ")])])])]),_c('b-table',{attrs:{"items":_vm.transcripts,"fields":_vm.transcriptFields,"responsive":"sm","small":""},scopedSlots:_vm._u([{key:"head(user_id)",fn:function(){return [_c('span',[_vm._v("User ID")])]},proxy:true},{key:"cell(user_id)",fn:function(data){return [_c('div',{style:({ backgroundColor: _vm.getUserColor(data.item.user_id) })},[_vm._v(" "+_vm._s(data.item.user_id)+" ")])]}},{key:"head(start)",fn:function(){return [_c('span',[_vm._v("Start")])]},proxy:true},{key:"cell(start)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.start)+" ")]}},{key:"head(end)",fn:function(){return [_c('span',[_vm._v("End")])]},proxy:true},{key:"cell(end)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.end)+" ")]}},{key:"head(text)",fn:function(){return [_c('span',[_vm._v("Text")])]},proxy:true},{key:"cell(text)",fn:function(data){return [_c('div',{staticClass:"text-left"},[_vm._v(_vm._s(data.item.text))])]}}])}),(_vm.chattingLogs.length)?[_c('h5',{style:({ marginTop: '32px' })},[_vm._v("통화 중 채팅 기록")]),_c('b-table',{attrs:{"items":_vm.chattingLogs,"fields":_vm.chattingLogFields,"responsive":"sm","small":""},scopedSlots:_vm._u([{key:"head(user_id)",fn:function(){return [_c('span',[_vm._v("User ID")])]},proxy:true},{key:"cell(user_id)",fn:function(data){return [_c('div',{style:({ backgroundColor: _vm.getUserColor(data.item.user_id) })},[_vm._v(" "+_vm._s(data.item.user_id)+" ")])]}},{key:"head(start)",fn:function(){return [_c('span',[_vm._v("Start")])]},proxy:true},{key:"cell(start)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.start)+" ")]}},{key:"head(text)",fn:function(){return [_c('span',[_vm._v("Text")])]},proxy:true},{key:"cell(text)",fn:function(data){return [_c('div',{staticClass:"text-left"},[_vm._v(_vm._s(data.item.text))])]}}],null,false,1006219950)})]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }