import axiosInstance from "./axiosInstance";

export async function fetchChattingLogByRoomId(roomId, headers) {
  try {
    const res = await axiosInstance.django.get(
      `/voice/v1/matching-logs/${roomId}/audio-files/call/chatting-logs/`,
      {
        headers: headers,
      }
    );
    if (res.status === 200) {
      return res.data.data;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
}
